<script setup>
	const props = defineProps({
		data: {
			type: Object,
			required: true,
		},
	});

	const { data } = toRefs(props);

	const type = computed(() => {
		const filters = data.value.filters || {};
		if (filters.tourType) return filters.tourType;
		return null;
	});

	const tag = computed(() => {
		return data.value.tags || null;
	});
</script>
<template>
	<ul v-if="type || tag" class="tour-header-pills">
		<li v-if="type" class="type">
			<MessLink :href="`/city-tours?tour-type=${type}`">
				<ProjectPill v-if="type" :text="type" bgColor="var(--gray-100)" />
			</MessLink>
		</li>
		<li v-if="tag" class="tag">
			<ProjectPill :text="tag" />
		</li>
	</ul>
</template>

<style lang="scss">
	.tour-header-pills {
		display: flex;
		gap: 2rem;
		margin-bottom: 1.5rem;

		.tag {
			.pill {
				border: 1.25px solid var(--gray-300);
			}
		}
	}
</style>
